.tableContainer {
  border: 1px solid var(--clr-theme-gray);
  min-height: 50vh;
}
.tableContainer .muiTypography-root {
  font-family: "Lato";
}
.tableContainer .table .tableHead .MuiTableCell-root {
  font-family: "Lato";
  font-weight: 700;
}
.tableContainer .table .tableBody .MuiTableCell-root {
  font-family: "Karla";
}
