.chartContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 30px;
  min-width: 75%;
  height: calc(70vh - 100px);
  overflow: hidden;
}
.alignFilters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 180px;
  gap: 10px;
}
.adjustChart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid grey;
  border-radius: 10px;
  height: calc(100vh - 100px);
  overflow: hidden;
}
.Generalstat {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 1rem 0rem;
}

.status {
  /* border: 1px solid grey; */
  margin: 1rem;
  padding: 1rem;
  text-align: "left";

  /* padding:0rem 0.5rem 0rem 0.5rem; */
  color: rgb(243, 245, 247);
}
/* .Generalstat .para{
  display:flex;
  flex-direction: column;
  background-color: var(--clr-theme-purple) !important;
}*/

