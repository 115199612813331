.policiesPageOverviewContainer {
    overflow-y: auto;
    display: grid;
    gap: 15px;
    padding: 2px;
    grid-template-areas: 
     "policies-area"
      "terms-area";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .policiesPageOverviewContainer::-webkit-scrollbar {
    display: none;
  }

  .buttons-area {
    grid-area: buttons-area;
    display: flex;
    justify-content: center;
    gap: 10px; 
    width: 100%; 
  }

  .buttons-area button {
    flex: 1; 
    margin: 0; 
    padding: 15px; 
    max-width: 100%; 

  }
  @media (min-width: 800px) {
    .policiesPageOverviewContainer {
      grid-template-columns:
        minmax(300px, 1.5fr)
        minmax(300px, 1.5fr);
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
      grid-template-areas: 
      "policies-area"
      "terms-area";
    }
  }

  .policies-area {
    grid-area: policies-area;
  }

  .terms-area {
    grid-area: terms-area;
  }



  .policiesPageOverviewContainer .policiesPageOverviewCard {
    height: 100%;
    border-radius: 10px;
    overflow-y: auto;
    background-color: var(--clr-theme-white);
    box-shadow: 1px 1px 10px var(--clr-theme-purple-50);
    border: 1px solid lightgrey;
  }

  .policiesPageOverviewContainer .policiesPageOverviewCard .MuiCardContent-root {
    height: 40vh;
    overflow-y: auto;
  }

  .homeCard {
    height: 100%;
    border-radius: 10px;
  }

  .quill-editor .ql-editor{
    height: calc(100vh - 355px);
  }