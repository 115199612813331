.custom-tabs .MuiTabs-indicator {
  background-color: #9c26b0 !important;
}

.custom-tab {
  color: #9c26b0 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}

.custom-tab.Mui-selected {
  color: #9c26b0 !important;
}

.MuiTab-root {
  text-transform: none !important;
}
