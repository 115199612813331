.customButton {
  background-color: var(--clr-theme-purple) !important;
}
.customButton:hover {
  background: linear-gradient(
    270deg,
    var(--ds-background-accent-purple-bolder, #403294) 0%,
    var(--ds-background-neutral-bold-pressed, #172b4d) 100%
  );
}
