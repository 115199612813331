.Reminders .MuiTypography-root {
  font-family: "Lato";
}
.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: var(--clr-theme-purple-20);
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
  border-bottom: 1px solid bisque;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l11 2s infinite;
}
@keyframes l11 {
  0% {
    background: conic-gradient(#9291f0 0, #0000 0);
  }
  12.5% {
    background: conic-gradient(#9291f0 45deg, #0000 46deg);
  }
  25% {
    background: conic-gradient(#9291f0 90deg, #0000 91deg);
  }
  37.5% {
    background: conic-gradient(#9291f0 135deg, #0000 136deg);
  }
  50% {
    background: conic-gradient(#9291f0 180deg, #0000 181deg);
  }
  62.5% {
    background: conic-gradient(#9291f0 225deg, #0000 226deg);
  }
  75% {
    background: conic-gradient(#9291f0 270deg, #0000 271deg);
  }
  87.5% {
    background: conic-gradient(#9291f0 315deg, #0000 316deg);
  }
  100% {
    background: conic-gradient(#9291f0 360deg, #0000 360deg);
  }
}
/* .highlighted-date {
  background-color: #9291f0;
 
  color: aliceblue;
} */

.highlight {
  background: #9291f0 !important; 
  color: black !important;
}

.react-calendar__tile {

  background: transparent ;
  color: inherit ;
}
