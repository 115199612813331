.header-menu-popover {
  overflow: visible;
  filter: drop-shadow(0px 2px 8px var(--clr-theme-gray));
  margin-top: 10px;
  background-color: var(--clr-theme-purple) !important;
  color: var(--clr-theme-white) !important;
}
.header-menu-popover .MuiAvatar-root {
  width: 32px;
  height: 32px;
  margin-left: -0.5;
  margin-right: 1;
}
.updatedLandingPage {
  height: 100vh;
  width: 100vw;
}
.updatedLandingPage header {
  z-index: 1000;
}
.updatedLandingPage .mainAppbar button {
  font-family: "Lato";
  font-weight: 500;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.updatedLandingPage .mainAppbar .brand {
  font-weight: 600;
  font-size: 1.5rem !important;
  text-transform: lowercase;
  font-family: 'Rnd Gthc Bk', sans-serif !important;
}
.updatedLandingPage .mainAppbar .MuiToolbar-root {
  background: var(--clr-theme-purple) !important;
  z-index: 9999;
}
.updatedLandingPage .landingPageMainContainer {
  flex: 1;
  max-height: calc(100vh - 64px);
}
.LoginModal .MuiDialog-paper {
  /* min-width: 35vw; */
  padding-right: 6%;
  padding-left: 6%;
  padding-top: 2%;
  padding-bottom: 2%;
  /* max-width: fit-content; */
  max-width: 900px;
}
.content-dialog {
  min-height: 70vh;
  overflow-y: auto;
}
.updatedLandingPage .landingPageMainContainer .MuiGrid-container {
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.updatedLandingPage .landingPageMainContainer .storycontentContainer {
  padding: 0 3rem;
  margin: 2rem 0;
}
.updatedLandingPage
  .landingPageMainContainer
  .storycontentContainer
  .MuiTypography-root {
  text-align: start;
  color: var(--clr-theme-gray);
  /* font-family: "Karla"; */
}
.updatedLandingPage .mainAppbar .active {
  position: relative;
}
.updatedLandingPage .mainAppbar .active::after {
  content: "";
  position: absolute;
  top: 3rem;
  width: 100%;
  height: 2px;
  background-color: var(--clr-theme-white) !important;
}
.updatedLandingPage .mainAppbar .notActive {
  border-bottom: none;
}
.updatedLandingPage .changeColor .MuiToolbar-root {
  background: var(--clr-theme-white) !important;
  z-index: 9999;
  box-shadow: 0px 5px 8px var(--clr-theme-gray);
}
.updatedLandingPage .changeColor .active::after {
  content: "";
  position: absolute;
  top: 3rem;
  width: 100%;
  height: 2px;
  background-color: var(--clr-theme-purple) !important;
}


.updatedLandingPage {
  overflow-x: hidden;
}