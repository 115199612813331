.accountPage {
  position: relative;
}

.accountPage .loginPaper {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background: var(--clr-white-20);
  min-width: 70%;
}

.accountPage .loginPaper > * {
  margin: 5px !important;
}

.accountPage .loginPaper .custom-button {
  background-color: var(--clr-theme-purple);
  color: var(--clr-theme-white);
  margin: 10px 0;
  border-radius: 20px;
  min-width: 50%;
}

.accountPage .loginPaper .custom-button:hover {
  background-color: var(--clr-theme-purple-50);
  color: var(--clr-text-dark);
}

.accountPage .MuiButton-fullWidth {
  max-width: 300px;
}

.accountPage .MuiFormHelperText-root {
  color: var(--clr-error);
}

.accountPage .MuiCard-root {
  padding: 10px;
  overflow-y: auto;
  width: 100%;
  max-width: 500px;
  background: transparent;
  box-shadow: none;
}

.accountPage .MuiCardHeader-root {
  text-align: center;
}

.accountPage .MuiCardHeader-title {
  color: var(--clr-theme-purple);
}

.accountPage .MuiCardActions-root {
  justify-content: center;
}

.accountPage .MuiDivider-root {
  width: 94%;
  margin: 20px !important;
  background-color: var(--clr-theme-gray);
}

.accountPage .MuiDivider-root::before {
  content: "or";
  background-color: var(--clr-theme-white);
  color: var(--clr-theme-gray);
  border-radius: 50%;
  padding: 2px 3px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.accountPage .MuiTextField-root {
  width: 100%;
}

.accountPage .profile-picture-container {
  position: relative;
  margin-bottom: 10px;
}

.accountPage .profile-picture-container .profile-upload-icon {
  position: absolute;
  translate: 150% -80%;
  padding: 10px;
}

.accountPage .signupinfoCard img {
  object-fit: contain;
}

.accountPage .signupinfoCard .instructions .MuiListItem-root {
  padding: 0;
  color: var(--clr-theme-purple);
}
.radio .MuiTypography-root {
  font-weight: bold;
}
