.box .clrblackheader {
  color: var(--clr-theme-gray-dark);
  font-family: 'Lato';
  font-weight: 500;
  font-size: 1.563rem;
}

.box .clrsubheader {
  font-family: 'Karla';
  font-weight: 400;
  color: var(--clr-theme-gray);
  font-size: 1.2rem;
  line-height: 1.46rem;
  padding: 5px;
}

.box .flippy-cardContainer div {
  background: var(--clr-theme-white);
  box-shadow: none;
  border-radius: 15px;
  text-align: center;
  transition: transform 1.8s ease;
  transform-style: preserve-3d; /* Add this line to enable 3D transformations */
}
.box .front-content,
.back-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden; /* Hide the backface during the flip */
  transform-style: preserve-3d; /* Add this line to enable 3D transformations */
}
@media (max-width: 1100px) {
  .back-content .clrsubheader {
    font-size: 1rem !important;
    line-height: 1.3 !important;
  }
}

@media (max-width: 850px) {
  .back-content .clrsubheader {
    font-size: 0.8rem !important;
    line-height: 1.3 !important;
  }
}
