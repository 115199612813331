.quickNotesButton {
    position: absolute !important;
    bottom: 16%;
    right: 2%;
    background-color: var(--clr-theme-purple) !important;
    color: var(--clr-theme-white) !important;
  }
  
  .quickNotesButton:hover {
    background-color: var(--clr-theme-purple) !important;
    color: var(--clr-theme-white);
  }
  
  .quickNotesDialog .MuiDialog-paper {
    overflow-y: hidden;
    width: 55vw;
    max-width: 90vw;
    box-shadow: none;
    min-width: 45vw;
    padding-right: 5%;
    padding-left: 5%;
    /* padding-top: 5%; */
    padding-bottom: 5%;
    justify-content: space-between;
  }
  
  .quickNotesDialog .dialogContentBox {
    max-height: 100%;
  }
  
  .quickNotesDialog .textfield {
    font-weight: 600;
    color: var(--clr-theme-purple);
  }
  
  .quickNotesDialog .MuiDialogTitle-root,
  .quickNotesDialog .MuiDialogTitle-root button {
    font-weight: bold;
    color: var(--clr-theme-white);
  
    background: linear-gradient(
      97.78deg,
      /* var(--ds-background-accent-purple-bolder, var(--clr-theme-purple)) 17.5%, */
        /* var(--ds-background-accent-magenta-subtle, var(--clr-theme-purple-50))
        113.39% */
    );
  }
  .symptom {
    gap: 10px;
  }
  .quickNotesDialog .MuiTypography-gutterBottom {
    color: var(--clr-theme-purple);
    font-family: "Lato";
    font-weight: 600;
  }
  .quickNotesDialog .saveButton {
    background-color: var(--clr-theme-purple);
  }
  