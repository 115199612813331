.patientOverviewContainer {
  height: calc(100vh - 100px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-areas: "profile-area" "Information-area" "Diagnosis-area" "Meds-area" "Therapy-area" "videos-area" "Attachment-area" "history-area" "statistics-area";
  gap: 8px;
  padding: 8px;
}
/* .patientOverviewContainer::-webkit-scrollbar {
  display: none;
} */

@media (min-width: 800px) {
  .patientOverviewContainer {
    grid-template-columns:
      minmax(300px, 0.6fr)
      minmax(300px, 1fr)
      minmax(300px, 1fr);
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "profile-area Meds-area Diagnosis-area "
      "Information-area videos-area Attachment-area"
      /* "Information-area videos-area videos-area" */
      "statistics-area statistics-area statistics-area";
  }
}

@media (max-width: 1000px) {
  .patientOverviewContainer {
    grid-template-columns:
      minmax(300px, 1fr)
      minmax(300px, 1fr);
    /* minmax(300px, 1fr); */
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "profile-area Information-area "
      "Meds-area Diagnosis-area"
      " videos-area Attachment-area"
      /* "Information-area videos-area videos-area" */
      "statistics-area statistics-area ";
  }
}
@media (max-width: 800px) {
  .patientOverviewContainer {
    grid-template-columns: minmax(300px, 1fr);
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      "profile-area"
      "Information-area"
      "Meds-area"
      "Diagnosis-area"
      "videos-area"
      "statistics-area";
  }
}

.patientOverviewContainer .patientOverview {
  min-height: calc(375px);
  background-color: var(--clr-theme-white);
  border-radius: 10px;
}

.patientOverviewContainer .patientOverviewCard {
  /* height: 100%; */
  height: 375px;
  border-radius: 20px;
  border: 1px solid lightgrey;
}

.statistics-area {
  grid-area: statistics-area;
}

.statistics-area .patientOverviewCard {
  height: 525px;
}
.statistics-area.patientOverview{
  min-height: calc(530px);
}

.profile-area {
  grid-area: profile-area;
}

.history-area {
  height: 50vh;
  overflow-y: auto;
  grid-area: history-area;
}

.videos-area {
  grid-area: videos-area;
}
.Diagnosis-area {
  grid-area: Diagnosis-area;
}
.Meds-area {
  grid-area: Meds-area;
}
.Attachment-area{
  grid-area: Attachment-area;
}
.Therapy-area {
  grid-area: Therapy-area;
}
.Information-area {
  grid-area: Information-area;
}

.patientDetailsList .MuiListItem-root {
  padding-top: 0;
  padding-bottom: 0;
}

.patientOverviewContainer .patientOverviewCard .MuiCardContent-root {
  height: 100%;
  overflow-y: auto;
  margin-top: -5px;
  padding: 0.5rem;
}

.historyPopover .MuiPopover-paper {
  padding: 10px;
  max-width: 50%;
  max-height: 50%;
}

.historyPopover .MuiPopover-paper li {
  padding: 0;
}

.historyPopover .MuiPopover-paper .MuiListItemText-primary {
  font-size: 0.75rem;
  color: var(--clr-theme-purple);
}

.historyList {
  height: 100%;
}

.historyList .MuiAvatar-root {
  background-color: var(--clr-theme-purple-50);
}

.historyList .MuiListItemSecondaryAction-root button {
  color: var(--clr-theme-purple);
}

.historyList .MuiListItemText-primary {
  color: var(--clr-text-dark);
}

.newVideosList .listItemBox {
  display: flex;
  justify-content: space-between; /* This keeps everything in one row */
  align-items: center; /* Vertically align items */
  width: 100%;
  gap: 10px; /* Add gap between the elements */
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0.5rem;
  margin: 8px;
  box-shadow: 0 0 7px var(--clr-theme-purple-50);
}
.newVideosList .dlistitem {
  width: 48%;
  box-shadow: 0 0 7px var(--clr-theme-purple);
  min-width: 325px;
  border-radius: 10px; 
  justify-content: space-between; 
  align-items: center; 
}
.newVideosList .listItemBox .MuiListItemText-secondary {
  font-size: 0.7rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  /* flex: 1; */
}

.newVideosList .username {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-family: "Lato";
  font-size: 1rem;
  line-height: 1.2; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 200px; 
}

.newVideosList .daysAgo span {
  font-weight: bold;
  font-size: 0.7rem;
  font-family: "Lato";
  color: var(--clr-theme-gray);
  white-space: nowrap; 
}

.patientOverview .MuiButton-contained,
.patientOverview .fullHeight.MuiCard-root {
  font-family: "Lato";
  text-transform: capitalize;
  font-size: 17px;
  line-height: 20px;
  /* border-radius: 20px !important; */
}

.patientOverview .patientOverviewHeader {
  min-width: 100px;
  text-align: end;
  background-color: var(--clr-theme-purple-50);
  border-radius: 10px;
}
.patientDetailsCards {
  min-height: 240px;
  min-width: 230px;
  border-radius: 15px !important;
}
.videoList {
  display: grid;
  column-gap: 10px;
  grid-template-columns: auto auto;
}

@media (max-width: 1024px) {
  .videoList {
    grid-template-columns: auto;
  }
}
@media (max-width: 700px) {
  .videoList {
    grid-template-columns: auto;
  }
  .newVideosList .dlistitem {
    min-width: 325px;
  }
}
.Patientdeatils .MuiListItemText-root {
  color: var(--clr-theme-purple);
}
.btn.MuiButton-root {
  color: var(--clr-theme-white);
  background-color: var(--clr-theme-purple);
}

.video-filters div.MuiInputBase-root.MuiOutlinedInput-root,
.video-filters .react-daterange-picker__wrapper {
  height: 2.5rem;
  border-radius: 10px;
}
.patientInformation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* border: 1px solid black; */
}
.patientInformationBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 10px;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.patientInformationBox .MuiFormControlLabel-root {
  width: 80%;
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
  cursor: default;
}

.patientInformation
  .patientInformationBox
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  font-family: "Lato";
  font-size: 1rem;
  font-weight: 700;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.63);
}
.patientInformation
  .patientInformationBox
  .MuiFormControlLabel-root
  .MuiTypography-root {
  font-family: "Lato";
  font-size: 1rem;
  line-height: 30px;
  color: var(--clr-theme-gray);
}
.patientDetailsCard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.imagePosition {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.patientDetailsCard .container .MuiListItemText-primary {
  font-family: "lato";
}
.patientDetailsCard .container .MuiListItemText-secondary {
  font-family: "Lato";
}
.patientDiagnosis .MuiListItemText-primary {
  font-family: "Lato";
  color: var(--clr-theme-gray);
}

.react-daterange-picker__inputGroup {
  min-width: auto !important;
}
.backdrop-container {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop-container .loader {
  color: #fff; /* Change the color of the loader */
}

.list-item {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
