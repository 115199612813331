.Treatment {
  color: var(--clr-theme-purple);
}
.Treatment .MuiOutlinedInput-root {
  background-color: var(--clr-theme-white);
  border-radius: 15px;
}

/* .dropdown-symptom {
  display:column;
  } */

.symptoms-dropdown-menu {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  background-color: var(--clr-theme-purple-20);
  box-sizing: border-box;
  color: var(--clr-theme-purple);
}
.check .h6::after {
  content: " \2705";
}
