.videoUploadButton {
  position: absolute !important;
  bottom: 6%;
  right: 2%;
  background-color: var(--clr-theme-purple) !important;
}

.videoUploadButton:hover {
  background-color: var(--clr-theme-purple) !important;
  color: var(--clr-theme-white) !important;
}

.videoUploadDialog .MuiDialog-paper {
  overflow-y: hidden;
  width: 55vw;
  max-width: 90vw;
  box-shadow: none;
  min-width: 45vw;
  padding-right: 1%;
  padding-left: 1%;
  padding-top: 1rem;
  justify-content: space-between;
}

.videoUploadDialog .dialogContentBox {
  max-height: 100%;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow-y: scroll;
}

.videoUploadDialog .textfield {
  font-weight: 600;
  color: var(--clr-theme-purple);
}

.videoUploadDialog .MuiTabs-root {
  padding-bottom: 15px;
}
.videoUploadDialog .MuiDialogTitle-root,
.videoUploadDialog .MuiDialogTitle-root button {
  font-weight: bold;
  /* color: var(--clr-theme-white); */

  background: linear-gradient(
    97.78deg,
    /* var(--ds-background-accent-purple-bolder, var(--clr-theme-purple)) 17.5%, */
      /* var(--ds-background-accent-magenta-subtle, var(--clr-theme-purple-50))
      113.39% */
  );
}
.symptom {
  gap: 10px;
}
.videoUploadDialog .MuiTypography-gutterBottom {
  color: var(--clr-theme-purple);
  font-family: "Lato";
  font-weight: 600;
}
.videoUploadDialog .saveButton {
  background-color: var(--clr-theme-purple);
}
.videoUploadDialog .tabs .MuiButtonBase-root {
  font-family: "Lato";
  text-transform: capitalize;
  border: 2px solid;
  width: 40%;
  margin-left: 10px;
}
.videoUploadDialog .tabs .Mui-selected {
  background-color: var(--clr-theme-purple);
  color: #fff;
}

.videoUploadDialog .tabs .MuiTabs-indicator {
  background-color: white;
}
.videoUploadDialog .tabs .MuiButtonBase-root:hover {
  background-color: black;
  color: #fff;
}

.dialogActionsFixed {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #ddd; 
  background: white; 
  position: sticky; 
  bottom: -20px; 
  left: 0;
  width: 100%; 
  z-index: 10; 
}
