.playVideoBox {
  overflow-y: auto;
  height: 100%;
  max-height: calc(100vh - 84px);
}

.carouselBox {
  width: 100%;
  

}

.carouselBox .css-hn784z,
.carouselBox .css-1abc02a {
  height: 75% !important;
}
.slide-label {
  background-color: var(--clr-theme-purple-50);
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 8px;
  margin-bottom: 10px;
  font-family: "Lato";
}
.Loder .MuiLinearProgress-root {
  background: none;
}
