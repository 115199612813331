.Team {
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
  /* justify-content: space-around; */
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.Team .box {
  flex: 1;
  min-width: 200px;
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid; */
}

.teamNote {
  color: var(--clr-theme-gray);
  text-align: center;
  min-width: 350px;
  width: 60%;
  font-family: "Karla";
  font-weight: 400;
  font-size: 1.2rem;
}

.Team .MuiAvatar-root.MuiAvatar-circular img:hover{
  scale: 1.1;
  transition: 1s linear 0s;
}

@media (min-width: 800px) {
  .Team .box {
    min-width: 25%;
  }
}