.featuresPages {
  padding: 0px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.featuresPages .content {
  padding-top: 1em;
  color: var(--clr-theme-white);
}

.featuresPages .box2 .box {
  /* background: var(--clr-theme-white); */
  /* padding: 2%; */
  /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); */
  flex: 1;
  min-width: 180px;
  min-height: 200px;
  overflow: hidden;
}

.featuresPages .MuiDivider-root {
  background-color: var(--clr-theme-purple-50) !important;
}

.partnersGrid img {
  max-height: 50px;
  max-width: 200px;
}