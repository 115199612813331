/* .allVideosBox {
  max-height: calc(100vh - 84px);
  overflow-y: auto;
}

.allVideosBox .MuiCard-root {
  margin: 10px;
  height: 30vh;
  aspect-ratio: 1/1;
  cursor: pointer;
  --box-shadow-width: 2px;
}

.allVideosBox .MuiCard-root:hover {
  --box-shadow-width: 15px;
}

.allVideosBox .playButton {
  top: -1.4em;
  float: right;
  color: var(--clr-theme-purple);
}

.allVideosBox .playButton svg {
  height: 50px;
  width: 50px;
}

.allVideosBox .MuiCardMedia-root {
  height: 60%;
}

.allVideosBox .MuiCard-root .MuiCardContent-root {
  padding: 5px;
  margin: 0;
}

.allVideosBox .MuiCardContent-root h6 {
  color: var(--clr-theme-purple);
  font-size: 0.9rem;
  font-weight: bold;
}

.allVideosBox .MuiCardActions-root {
  width: 100%;
  justify-content: end;
}

.allVideosBox .MuiCardActions-root .caption {
  font-size: 0.75rem;
  color: var(--clr-theme-purple);
  align-items: flex-end;
}

.allVideosBox .viewed {
  box-shadow: 0 0 var(--box-shadow-width) var(--clr-success);
}

.allVideosBox .notviewed {
  box-shadow: 0 0 var(--box-shadow-width) var(--clr-error);
}
.btn.MuiButton-root {
  color: var(--clr-theme-white);
  background-color: var(--clr-theme-purple);
} */

.allVideosBox {
  height: 100%;
}

.allVideosBox .listItemBox{
  justify-content: space-evenly;
  width: 90%;
  gap: 10px;
  padding: 0.5;
  border-radius: 10px;
  margin: 8px;
  padding-left: 5px;
  box-shadow: 0 0 7px var(--clr-theme-purple-50);
}

.allVideosBox .dlistitem {
  width: 48%;
  box-shadow: 0 0 7px var(--clr-theme-purple);
  justify-content: space-evenly;
  min-width: 400px;
  border-radius: 10px;
}

.videoList {
  display: grid;
  column-gap: 10px;
  /* grid-template-columns: auto auto; */
}

.allVideosBox .username {
  color: var(--clr-theme-gray);
  font-weight: 400;
  font-family: "Lato";
  font-size: 1rem;
  line-height: 19.2px;
}

.notviewed:hover{
  box-shadow: 0px 0px 10px red !important;
}

.viewed:hover{
  box-shadow: 0px 0px 10px green !important;
}
