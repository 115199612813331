.loginLayout {
  width: 100vw;
  height: 100%;
  background-color: var(--clr-theme-white);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pageBgImage {
  background-size: cover;
  background-repeat: no-repeat;
}

.loginLayout .leftBox {
  flex: 0.6;
  flex-direction: column;
}

.loginLayout .rightBox {
  flex: 0.4;
}

.loginLayout .mission-statement {
  color: var(--clr-theme-purple);
  width: 60%;
  text-align: center;
  font-size: 1.2rem;
  padding-top: 1em;
  font-style: italic;
}
