.settings-tabs .MuiTab-root {
  color: var(--clr-theme-purple);

  align-items: flex-start;
  text-align: left;

  /* Optional css below */
  text-transform: capitalize !important;
  font-size: 1.1rem;
}
.settings-tabs .Mui-selected {
  border-radius: 10px;
  color: var(--clr-theme-white) !important;
  background-color: var(--clr-theme-purple);
}

.changePasswordBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
}

.changePasswordPage {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.changePasswordBox .MuiFormControlLabel-root {
  width: 100%;
  justify-content: space-between;
}

.changePasswordPage .btnChangePassword {
  width: 30%;
}
