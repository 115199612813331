.MainLayout {
  display: flex;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--clr-theme-white);
}

/* Material UI css override on whole app */
.MainLayout .MuiCardHeader-root {
  /* background-color: var(--clr-theme-purple-50); */
}

.MainLayout .MuiCardContent-root {
  overflow-y: auto;
}

.MainLayout .MuiCardHeader-root .MuiCardHeader-title {
  font-family: "Lato";
  font-weight: 400;
  /* color: var(--clr-theme-white); */
}
