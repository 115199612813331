.footer {
  background-color: var(--clr-theme-grayWhite)
}

.footer .footerGrid {
 color: var(--clr-theme-gray);
 font-family: Lato;
 font-weight: 800;
 line-height: 3rem;
 margin-bottom:0.2rem ;

}
.footer .MuiList-root{
 min-width: 14vw;
 
}
.footer .MuiListItemText-primary{
  font-size: 1.1rem;
  line-height: 2rem;
  font-family: "Karla";
  color: var(--clr-theme-gray-dark);
}
.footer .MuiSvgIcon-root{
  font-size: 1.9rem ;
}
.footer .text{
  font-size: 1.1rem;
 
}