.historicalOverview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.assessmentBox {
  width: 100%;
  height: 370px;
  border-radius: 10px;
  border: 1px solid black;
  padding: 20px;
  overflow-y: auto;
}

.documentsUploadBox {
  width: 100%;
  /* height: 370px; */
  height: 100%;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
  padding: 30px;
}

.crisisBox,
.goalsBox,
.notesBox {
  width: 100%;
  /* max-width: 378px; */
  display: flex;
  flex-direction: column;
  height: 240px;
  /* padding: 10px; */
  border-radius: 5px;
  border: 1px solid black;
  background-color: #f1f1f1;
}

.cardActions {
  margin-top: auto;
  justify-content: flex-end;
}

.patient-filter div.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 10px;
}

.list-item {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.primary {
  font-weight: bold;
}

.secondary {
  margin-top: 10px;
  color: #666;
}

body {
  font-family: Arial, sans-serif;
  padding: 1;
}

.qa-item,
.qa-item1 {
  margin: 20px;
  display: flex;
  padding: 1;
}
.qa-item {
  align-items: end;
  justify-content: end;
}
.qa-item1 {
  align-items: start;
  justify-content: start;
}

.question-container {
  background-color: #dce8e6;
  max-width: 550px;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  box-shadow: #666;
}

.answer-container {
  background-color: #f1f1f1;
  padding: 5px 20px;
  border-radius: 5px;
  max-width: 550px;
  margin-top: 10px;
  margin-left: 10px;
}

.question {
  font-weight: bold;
  color: #333;
}

.answer {
  color: #333;
}

.assessmentAnalytics .MuiButton-root {
  width: 50%;
  min-width: 50%;
}
.behaviourGoals .MuiButton-root {
  width: 50%;
  min-width: 50%;
}
.expandButton{
  align-items: center;
  justify-content: flex-end;
  z-index: 99999;
  
}