.statistics .MuiInputBase-root {
  border-radius: 10px;
}
.responsive {
  width: 100%;
  justify-content: center;
  margin: 0.5rem;
}
.noData {
  margin-top: 6rem;
}
.chartContainerP {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  min-width: 75%;
  height: calc(50vh - 100px);
  overflow: hidden;
}
.alignFiltersP {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.adjustChartP {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;
}

.status {
  margin: 1rem;
  padding: 1rem;
  text-align: 'left';
  color: rgb(243, 245, 247);
}
