.BrandLogo {
  width: 100%;
  gap: 5px;
}

.BrandLogo img {
  width: 50px;
  height: 55px;
}

.brand-name {
  color: var(--clr-theme-purple);
  margin: 10px !important;
}

/* .brandText {
  margin: 10px;
  color: var(--clr-theme-purple);
} */
.brand{
 font-weight: 600;
/* font-size: 6rem !important; */
text-transform: lowercase;
font-family: 'Rnd Gthc Bk', sans-serif !important;
color: var(--clr-theme-purple)
}