.left-drawer {
  display: flex;
  flex: 1;
}

.left-drawer .topSection {
  /* flex: 1; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center; 
 justify-content: center;
  
  /* gap: 1rem; */
}
.left-drawer .bottomSection {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.left-drawer .BrandLogo {
  padding: 10px 0;
}

.left-drawer .MuiDrawer-paper {
  /* background-color: var(--clr-theme-purple); */
  background-color: #E9EAFF;
  border: none;
  will-change: transform, width; 
  
}

.left-drawer .nonSelectedIcon {
  /* color: var(--clr-theme-white) !important; */
  color: var(--clr-theme-gray-dark);
}

.left-drawer .MuiList-root {
  /* color: var(--clr-theme-white) !important; */
}
.left-drawer .MuiList-root .MuiTypography-root {
  font-family: "Lato";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  /* color: black; */
}

.left-drawer .collapsedList {
  max-height: 40vh;
  overflow-y: auto;
}

.left-drawer .MuiList-root .selected {
  background: var(--clr-theme-white);
  border-radius: 7px;
  color: var(--clr-theme-purple);
}
.left-drawer .selectedIcon {
  color: var(--clr-theme-purple) !important;
}

.left-drawer .selectedPatient {
  background: var(--clr-theme-white) !important;
  color: var(--clr-theme-purple) !important;
  border-radius: 7px;
}

.left-drawer .bottomSection span, .left-drawer .bottomSection svg{
  font-size: 1rem !important;
}