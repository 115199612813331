.viewGoalsOverviewContainer {
  display: grid;
  gap: 15px;
  padding: 2px;
  overflow-y: auto;
  grid-template-columns: 35% 35% 30%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    ' teacher-goals-area teacher-goals-area teacher-patient-profile-area'
    'teacher-in-perspective-area teacher-progress-area teacher-patient-profile-area';
}
.viewGoalsOverviewContainer::-webkit-scrollbar {
  display: none;
}

.viewGoalsOverviewContainer .teacher-goals-area {
  grid-area: teacher-goals-area;
}
.viewGoalsOverviewContainer .teacher-in-perspective-area {
  grid-area: teacher-in-perspective-area;
}
.viewGoalsOverviewContainer .teacher-progress-area {
  grid-area: teacher-progress-area;
}

.viewGoalsOverviewContainer .teacher-patient-profile-area {
  grid-area: teacher-patient-profile-area;
  grid-column: 3;
  grid-row: span 2;
  height: 100%;
  overflow: visible;
}

.viewGoalsOverviewContainer .viewGoalsOverviewCard {
  height: 100%;
  border-radius: 10px;
  overflow-y: visible;
  background-color: var(--clr-theme-white);
  box-shadow: 1px 1px 10px var(--clr-theme-purple-50);
  border: 1px solid lightgrey;
}

.viewGoalsOverviewContainer .viewGoalsOverviewCard {
  border: 1px solid lightgrey;
}

.viewGoalsOverviewContainer .viewGoalsOverviewCard .MuiCardContent-root {
  height: 40vh;
  overflow-y: visible;
}

.viewAllButton {
  background-color: var(--clr-theme-white) !important;
  color: var(--clr-theme-gray) !important;
  text-transform: capitalize !important;
  border: 1px solid gray;
  font-family: 'Lato' !important;
  font-weight: 400;
  width: 8.625rem !important;
}
