.updatedLandingPage .loginButton {
  border-radius: 3rem;
  text-transform: capitalize;
  font-family: "Lato";
  margin-top: 3rem;
  margin-left: 0.8rem;
}

.updatedLandingPage .mainHomeHeader {
  font-style: italic;
  font-weight: 400;
  color: var(--clr-theme-white);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.updatedLandingPage .homeSection .MuiBox-root {
  flex: 1;
  text-align: center;
}

.content{
  margin: 2rem;
  max-width: 100%;
  border: 2px solid black;
}