.videoReviewPaper {
  max-height: 99%;
  max-width: 100%;
  overflow: auto;
}

.Patientreview .MuiCard-root {
  background-color: rgba(229, 229, 250, 1);
}
.Patientreview .MuiCardHeader-title {
  font-family: "Lato";
}
.Patientreview .MuiCardContent-root .MuiTypography-root {
  font-family: "Lato";
}
