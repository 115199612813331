.add-goal-details {
  background-color: #E9EAFF;
  padding: 3rem;
  border-radius: 5px;
  max-width: 100%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px; 
  margin-top: 24px;
}


.heading {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
  margin-bottom: 16px;
}

.button {
  display: flex;
  width: 90% !important;
  min-width: 200px;
  height: 48px;
  align-items: center;
  background-color: var(--clr-theme-purple) !important;
  border-color: var(--clr-theme-purple) !important;
  color: white !important; 
  font-family: 'Lato' !important; 
  text-transform: capitalize !important; 
}

.grid-item {
  grid-column: span 12;
}

.grid-item-md {
  grid-column: span 8;
}

.select-student-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px; 
}

.select-student-typography {
  font-family: 'Lato' !important;
  font-size: 18px;
  white-space: nowrap;
}

.select-student-form-control {
  min-width: 200px;
}

.student-info-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; 
}

.add-goal-details .MuiAvatar-root {
  height: 70px;
  width: 70px;
  margin-right: 8px;
}

.student-textfield {
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 8px;
}

.goal-box {
  display: flex;
  align-items: center;
  gap: 16px; 
  margin-bottom: 16px;
}

.goal-box-flex {
  flex: 1;
}

.goal-box-typography {
  font-family: 'Lato' !important;
  font-size: 18px;
  white-space: nowrap;
  margin-bottom: 8px;
}

.goal-textfield {
  margin-bottom: 16px;
}

.add-goal-details .MuiInputBase-root{
  background-color: white;
}