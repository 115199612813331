.fc .fc-more-link {
  color: #9C26B0 !important;
}

.calendar-box {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.edit-events {
  flex: 0.2;
  padding: 5px;
  border-radius: 15px 0px 0px 15px;
  background-color: var(--clr-theme-white);
}

.fc-event-end {
  margin-right: 2px;
}

.fc-toolbar-title {
  font-weight: 600;
}

.fc-view-harness {
  background-color: var(--clr-theme-white);
  flex: 1;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-bootstrap5 {
  height: 100% !important;
  display: flex;
  flex: 0.8;
}

.fc-daygrid-day-events {
  border-radius: 10px;
}

/* Has meetings on the day */
.fc-daygrid-day-frame:has(.fc-daygrid-day-events .fc-daygrid-event-harness) {
  background-color: var(--clr-theme-white);
}

.meet-link {
  background-color: var(--clr-google-meet);
  color: var(--clr-theme-gray);
}

.zoom-link {
  background-color: var(--clr-theme-purple-50);
  color: var(--clr-theme-gray);
}

.no-link {
  color: var(--clr-error);
}



.fc-daygrid-day-top {
  background-color: var(--clr-theme-purple-20);
}

.textFieldBox-css {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 6px;
  gap: 8px;
}

.dialogAddevent .dialog-content {
  padding-top: 20px;
  max-height: 800px;
  overflow-y: auto;
  padding: 2px;
}

.events-TitleContent {
  height: 80%;
  width: 180px;
  border-left: 4px solid var(--clr-theme-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 4px;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-appointments .MainLayout .MuiDrawer-docked.left-drawer,
.dashboard-appointments .MainLayout .MuiToolbar-root.MuiToolbar-regular.header {
  display: none;
}

.dashboard-appointments .calendar-box,
.dashboard-appointments .fc-header-toolbar.fc-toolbar {
  flex-direction: column-reverse;
}
.dashboard-appointments .MainLayout {
  height: 92%;
}
.edit-events ul.MuiList-root.MuiList-padding.MuiList-subheader {
  height: calc(100vh - 400px);
}

.appointments-list {
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-bootstrap5 {
    min-height: 350px;
    max-height: 500px;
  }
}
