.homePageOverviewContainer,
.patientHomePageOverviewContainer {
  overflow-y: auto;
  display: grid;
  gap: 15px;
  padding: 2px;
}
.homePageOverviewContainer {
  grid-template-areas: 'dashboardVideos-area' 'dashboardStatistics-area' 'appoinments-area';
}

.patientHomePageOverviewContainer {
  grid-template-areas: 'dashboardVideos-area' 'dashboardStatistics-area';
}

.homePageOverviewContainer::-webkit-scrollbar,
.patientHomePageOverviewContainer::-webkit-scrollbar {
  display: none;
}

@media (min-width: 800px) {
  .homePageOverviewContainer {
    grid-template-columns:
      minmax(300px, 1.5fr)
      minmax(300px, 0.6fr);
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      'dashboardVideos-area appoinments-area'
      'dashboardStatistics-area appoinments-area';
  }
}

.dashboardVideos-area {
  grid-area: dashboardVideos-area;
}
.dashboardStatistics-area {
  grid-area: dashboardStatistics-area;
}
.appoinments-area {
  grid-area: appoinments-area;
}
.homePageOverviewContainer .homePageOverviewCard,
.patientHomePageOverviewContainer .homePageOverviewCard {
  height: 100%;
  border-radius: 10px;
  overflow-y: auto;
  background-color: var(--clr-theme-white);
  box-shadow: 1px 1px 10px var(--clr-theme-purple-50);
  border: 1px solid lightgrey;
}
.homePageOverviewContainer .homePageOverviewCard {
  border: 1px solid lightgrey;
}

.homePageOverviewContainer .homePageOverviewCard .MuiCardContent-root,
.patientHomePageOverviewContainer .homePageOverviewCard .MuiCardContent-root {
  height: 40vh;
  overflow-y: auto;
}

.homeCard,
.linechart {
  height: 100%;
}
.homeCard {
  border-radius: 10px;
}

.viewAllButton {
  background-color: var(--clr-theme-white) !important;
  color: var(--clr-theme-gray) !important;
  text-transform: capitalize !important;
  border: 1px solid gray;
  font-family: 'Lato' !important;
  font-weight: 400;
  width: 8.625rem !important;
}
