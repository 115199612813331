.header {
  /* border-radius: 10px; */
  padding: 0px 10px;
  box-shadow: 1px 1px 10px var(--clr-theme-purple-50);
  /* background-color: var(--clr-theme-purple); */
  position: sticky; 
  top: 0; 
  z-index: 1100;
  background-color: #E9EAFF;
}
.renderBtn .MuiButtonBase-root {
  border: 1px solid;
  background-color: var(--clr-theme-purple) !important;
  color: var(--clr-theme-white);
  text-transform: capitalize;
  width: 100%;
  font-size: 1.1rem;
}
