.videoFeedbackPaper {
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  /* background-color: var(--clr-theme-white) !important; */
}

.videoFeedbackPaper .treatmentBox .GoalsBox{
  border-radius: 10px;
  width: 100%;
  box-shadow: 1px 1px 5px var(--clr-theme-purple-50);
  border: 1px solid var(--clr-theme-purple-50);
}
.videoFeedbackPaper .treatmentBox .cardContent .MuiTypography-root {
  font-family: "Lato";
  color: var(--clr-theme-purple);
  font-size: 1rem;
}

.videoFeedbackPaper .treatmentBox .MuiList-subheader {
  font-size: 1rem;
  font-family: "Lato";
  color: var(--clr-theme-purple);
}

.videoFeedbackPaper .treatmentBox .MuiCardContent-root ,
.videoFeedbackPaper .treatmentBox .MuiCardHeader-root {
  padding: 10px;
}

.videoFeedbackPaper .treatmentBox .MuiCardHeader-root {
  border-bottom: 1px solid var(--clr-theme-purple-50);
}
.videoFeedbackPaper .treatmentBox .MuiCardHeader-root .MuiTypography-root {
  font-family: "Lato";
  color: var(--clr-theme-purple);
  font-size: 1.5rem;
}
.videoFeedbackPaper .MuiOutlinedInput-root {
  border-radius: 5px;
  background-color: var(--clr-theme-purple-20);
}
/* .custom-slider .MuiSlider-valueLabel{
  left: auto;
  right: 0;
} */


.custom-slider .MuiSlider-markLabel {
  font-size: 0.68rem;
}

.disabled-dropdown {
  pointer-events: none;
  opacity: 0.5; /* or any other styling to indicate disabled state */
  background-color: rgba(0, 0, 0, 0.38) !important;
}
.GoalsBox{
  background-color: rgba(141, 140, 140, 0.38) !important;
  padding: 10px;
}
.Cardheading{
  font-family: "Lato";
  font-size: 1.2rem;
  margin-left: 5px;
}
.goalsContainer .MuiOutlinedInput-root,
.goalsContainer .MuiOutlinedInput-root input {
  border-radius: 10px;
  background-color: white; 
  
}
.Select{
  border-radius: 10px;
  width: 100%;
  box-shadow: 1px 1px 5px var(--clr-theme-purple-50);
  border: 1px solid var(--clr-theme-purple-50);
  height:60px;
  background-color: rgba(141, 140, 140, 0.38) !important;
  display: flex;
  /* justify-content:space-between; */
  align-items: center;

}
.input1{
  font-family: "Lato";
  font-size: 1.2rem;
  margin-left: 5px;

}
.input2{
  width:5px;
}
