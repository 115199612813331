.teacherDashboardOverviewContainer::-webkit-scrollbar {
  display: none;
}

.teacherDashboardOverviewContainer {
  display: grid;
  overflow-y: auto;
  gap: 15px;
  padding: 2px;
  grid-template-areas:
    'teacher-dashboardGoals-area'
    'teacher-dashboardStatistics-area'
    'teacher-appoinments-area';
}

@media (min-width: 800px) {
  .teacherDashboardOverviewContainer {
    grid-template-columns: minmax(300px, 1.5fr) minmax(300px, 0.6fr);
    grid-template-rows: auto auto;
    grid-template-areas:
      'teacher-dashboardGoals-area teacher-appoinments-area'
      'teacher-dashboardStatistics-area teacher-appoinments-area';
  }
}

.teacher-dashboardGoals-area {
  grid-area: teacher-dashboardGoals-area;
}
.teacher-dashboardStatistics-area {
  grid-area: teacher-dashboardStatistics-area;
}
.teacher-appoinments-area {
  grid-area: teacher-appoinments-area;
}

.teacherDashboardOverviewContainer .teacherDashboardOverviewCard {
  height: 100%;
  border-radius: 10px;
  overflow-y: auto;
  background-color: var(--clr-theme-white);
  box-shadow: 1px 1px 10px var(--clr-theme-purple-50);
  border: 1px solid lightgrey;
}
.teacherDashboardOverviewContainer .teacherDashboardOverviewCard {
  border: 1px solid lightgrey;
}

.teacherDashboardOverviewContainer
  .teacherDashboardOverviewCard
  .MuiCardContent-root {
  height: 40vh;
  overflow-y: auto;
}

.homeCard,
.linechart {
  height: 100%;
}
.homeCard {
  border-radius: 10px;
}

.viewAllButton {
  background-color: var(--clr-theme-white) !important;
  color: var(--clr-theme-gray) !important;
  text-transform: capitalize !important;
  border: 1px solid gray;
  font-family: 'Lato' !important;
  font-weight: 400;
  width: 8.625rem !important;
}
